import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";

import { urlFor, client } from "../../client";
import { icons, imgs } from "../../constants";

import "./Footer.scss";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  // structuring form data
  const { name, email, message } = formData;

  const hnadleChnageInput = (e) => {
    const { name, value } = e.target;

    //destructuring form Data
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: name,
      email: email,
      message: message,
    };

    client.create(contact).then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    });
  };

  return (
    <>
      <div className="app__contact-container app__flex">
        <div className="head-title">
          Take A <span>Coffe</span> and <span>Chat</span> with me.
        </div>

        <div className="app__footer-cards">
          <div className="app__footer-card">
            <img src={imgs.emailIcon} alt="email" />

            <a href="mailto:ola@sardarzai.com" className="p-text">
              olá@sardarzai.com
            </a>
            <img src={imgs.mobileIcon} alt="mobile" />

            <a href="tel:+351933655069" className="p-text">
              +351933655069
            </a>
          </div>
        </div>

        {/* form started */}
        {/* <div className="app__mar"> */}
        {!isFormSubmitted ? (
          <div className="app__footer-form  app__flex">
            <div className="app__flex">
              <input
                type="text"
                className="p-text"
                placeholder="Your Name"
                name="name"
                value={name}
                onChange={hnadleChnageInput}
              />
            </div>

            <div className="app__flex">
              <input
                type="email"
                className="p-text"
                placeholder="Your Email"
                name="email"
                value={email}
                onChange={hnadleChnageInput}
              />
            </div>

            <div className="app__flex">
              <textarea
                className="p-text"
                placeholder="Your Message"
                name="message"
                value={message}
                onChange={hnadleChnageInput}
              />
            </div>

            <button type="button" className="p-text" onClick={handleSubmit}>
              {loading ? "Sending" : "Send Message"}
            </button>
          </div>
        ) : (
          <div>
            <h3 className="head-text">Thank you for getting in touch!</h3>
          </div>
        )}
        {/* </div> */}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "Contact",
  "app__whitebg"
);
