import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
//css Stylesheet
import "./Navbar.scss";
//icons
import { icons } from "../../constants";
//images
import { imgs } from "../../constants";

//navbar design started
const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  // const li = document.querySelectorAll(...`#${item.text}`);
  // const changeScrollValue = () => {
  //   let len = li.length;
  //   while (--len && window.scrollY + 97 < li[len].offsetTop) {}
  //   li.forEach(ltx >= ltx.classList.remove("active"));
  //   li[len].classList.add("active");
  //   const scrollValue = document.documentElement.scrollTop;
  //   if (scrollValue > 100) {
  //     setIsActive(true);
  //   } else {
  //     setIsActive(false);
  //   }
  // };

  // window.addEventListener("scroll", changeScrollValue);
  const menuListItems = [
    { icon: <icons.FiHome />, text: "Home" },
    { icon: <icons.FiUser />, text: "About" },
    { icon: <icons.AiOutlineFileDone />, text: "Work" },
    { icon: <icons.GiSkills />, text: "Skills" },
    { icon: <icons.MdRateReview />, text: "Testimonial" },
    { icon: <icons.RiContactsBook2Fill />, text: "Contact" },
  ];

  return (
    <div className="my-navbar ">
      <div className="app__profiles-info app__flex">
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.5, type: "tween" }}
          className="app__profile-item-img"
        >
          <div className="about-me">
            <div className="about-me-img-bg">
              <div className="about-me-img">
                <img src={imgs.mypic} alt="my-image" />
                <h2>SARDARZAI</h2>
                <h5>Full Stack Developer</h5>
                <a href="#Contact">
                  {/* <span>
                    <icons.GrContactInfo />
                  </span> */}
                  Hire Me
                </a>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className="my-nav-menu">
        {/* map menu list */}
        <ul>
          {menuListItems.map((item, index) => (
            <li
              key={item.text}
              // className={"my-nav-list" + item.text == item.text ? "active" : ""}
              onClick={() => setIsActive(`#${item.text}`)}
              className={
                isActive == `#${item.text}`
                  ? "my-nav-list active"
                  : "my-nav-list"
              }
            >
              <b></b>
              <b></b>
              <a href={`#${item.text}`}>
                <span className="my-nav-list-icon ">
                  {item.icon} <span>{item.text}</span>
                </span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* address and location started */}

      <div className="app__navbar-location app__flex">
        <span>
          <icons.GoLocation />
        </span>

        <p className="p-text">Braga Portugal</p>
      </div>

      {/* contact started */}

      <div className="my-nav-contact">
        <div className="nav-contact-title">FOLLOW ME</div>
        <div className="nav-contact-icons">
          <a href="https://github.com/Sardarzai/" target="_blank">
            <icons.BsGithub />
          </a>
          <a href="https://www.linkedin.com/in/sardarzaidev/" target="_blank">
            <icons.BsLinkedin />
          </a>
          <a href="https://www.facebook.com/sardarzaidev/" target="_blank">
            <icons.BsFacebook />
          </a>
          <a href=" https://www.youtube.com/c/elearncheap/" target="_blank">
            <icons.BsYoutube />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
