import React from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";

import { urlFor, client } from "../../client";
import { icons, imgs } from "../../constants";

import "./Header.scss";
const Header = () => {
  return (
    <div className="app__header-container">
      {/* left site heading */}
      <div className="app__header-info-container">
        <motion.div
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 2 }}
          className="app__header-info"
        >
          <div className="app__header-badge">
            <div className="badge-cmp app__flex">
              <span>👋</span>
              <div style={{ marginLeft: 20 }}>
                <p className="p-text">Hello, I'm</p>
                <h1 className="head-text">Sardarzai</h1>
              </div>
            </div>
            <div className="tag-cmp app__flex">
              <p className="p-text">Full-Stack Developer</p>
              <p className="p-text">Freelancer</p>
            </div>
          </div>
        </motion.div>
        <div className="circles-left">
          {[imgs.cssIcon, imgs.cIcon, imgs.htmlIcon, imgs.githubIcon].map(
            (circle, index) => (
              <div className="circle-cmp app__flex" key={`circle-${index}`}>
                <img src={circle} alt="circle" />
              </div>
            )
          )}
        </div>
      </div>
      {/* center image */}

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 2, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={imgs.headerProfile} alt="" />
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={imgs.headerImageCircle}
          alt="profile-circle"
          className="overlay-circle"
        />
      </motion.div>

      {/* header icons */}

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 2 }}
        className="app__header-circles app__flex"
      >
        <div className="circles-right">
          <div>
            {[imgs.reactIcon, imgs.nodeIcon, imgs.sassIcon, imgs.mysqlIcon].map(
              (circle, index) => (
                <div className="circle-cmp app__flex" key={`circle-${index}`}>
                  <motion.img
                    whileHover={{ scale: 1.2, cursor: "pointer" }}
                    whileTap={{ scale: 0.8 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.5, type: "tween" }}
                    src={circle}
                    alt="circle"
                  ></motion.img>
                </div>
              )
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(Header, "app__header"),
  "Home",
  "app__whitebg"
);
