import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
// import reactTooltip from "react-tooltip";

import PrograssBar from "./PrograssBar";
import SkillsPrograssBar from "./SkillsPrograssBar";

import { urlFor, client } from "../../client";
import { icons, imgs } from "../../constants";

import "./Skills.scss";
import ReactTooltip from "react-tooltip";

const Skills = () => {
  //fetching data..
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  useEffect(() => {
    // fetching experiences
    const experiences_query = '*[_type == "expreience"]';
    client.fetch(experiences_query).then((data) => {
      setExperiences(data);
    });
  }, []);

  return (
    <>
      <div className="head-title">
        <span>Skills</span> & <span>Experience</span> History
      </div>
      <PrograssBar />

      {/* skills and job history */}
      <div className="app__skills-container">
        {/* skills-title */}
        {/* skills cards */}
        <div className="app__skills-list ">
          <div className="skills-title p-text">Skills</div>
          <SkillsPrograssBar />
        </div>

        {/* experience */}
        <div className="app__skills-exp-container">
          {/* //experience title */}
          <div className="exp-title p-text">CERTAIN RECENT WORK EXPERIENCE</div>
          {experiences.map((experience) => (
            <motion.div
              whileHover={{ scale: 1.2, rotate: 5 }}
              className="app__skills-exp"
              key={experience.company}
            >
              <div className="app__skills-exp-content">
                <div className="title-section">
                  <div className="title">
                    <h4 className="bold-text">{experience.company}</h4>
                    <p className="p-text">{experience.position}</p>
                  </div>

                  <div className="exp-year">
                    <p className="p-text">{experience.year}</p>
                  </div>
                </div>
                <div className="respons p-text">
                  <ul>
                    <li>
                      <span>
                        <icons.IoCheckmarkDoneCircle />
                      </span>
                      {experience.respons[0]}
                    </li>
                    <li>
                      {" "}
                      <span>
                        <icons.IoCheckmarkDoneCircle />
                      </span>
                      {experience.respons[1]}
                    </li>
                    <li>
                      {" "}
                      <span>
                        <icons.IoCheckmarkDoneCircle />
                      </span>
                      {experience.respons[2]}
                    </li>
                    <li>
                      {" "}
                      <span>
                        <icons.IoCheckmarkDoneCircle />
                      </span>
                      {experience.respons[3]}
                    </li>
                    <li>
                      {" "}
                      <span>
                        <icons.IoCheckmarkDoneCircle />
                      </span>
                      {experience.respons[4]}
                    </li>
                  </ul>
                </div>
              </div>

              {/* contact end here */}
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__Skills"),
  "Skills",
  "app__whitebg"
);
