import { BsFacebook } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { IoCheckmarkDoneCircle } from "react-icons/io5";

// navbar icons
import { GrContactInfo } from "react-icons/gr";
import { FiHome } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { AiOutlineFileDone } from "react-icons/ai";
import { GiSkills } from "react-icons/gi";
import { MdRateReview } from "react-icons/md";
import { RiContactsBook2Fill } from "react-icons/ri";
import { GoLocation } from "react-icons/go";

import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

export default {
  // navbar icons started
  // profile
  GrContactInfo,
  // menu

  //
  FiHome,
  FiUser,
  AiOutlineFileDone,
  GiSkills,
  MdRateReview,
  RiContactsBook2Fill,
  BsFacebook,
  BsGithub,
  BsLinkedin,
  BsYoutube,
  BsEye,
  GoLocation,

  //body
  IoCheckmarkDoneCircle,
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
};
