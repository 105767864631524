import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AppWrap } from "../../wrapper";

//import owl carousel
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { urlFor, client } from "../../client";
import "./Work.scss";
import { icons, imgs } from "../../constants";

const Work = () => {
  const [activeFilter, setactiveFilter] = useState("All");
  const [animateCard, setanimateCard] = useState({ y: 0, opacity: 1 });
  const handleWorkFilter = (item) => {
    setactiveFilter(item);
    setanimateCard([{ y: 100, opacity: 0 }]);
    setTimeout(() => {
      setanimateCard([{ y: 0, opacity: 1 }]);

      if (item === "All") {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  //fetching data..
  useEffect(() => {
    const query = '*[_type == "projects"]';
    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    });
  }, []);

  return (
    <>
      <div className="app__work-container">
        {/* title section started */}

        <div className="head-title">
          My Creative <span>Portfolios</span>
          <p className="p-text">
            These portfolios are examples of several projects I've made using
            various stacks and technologies. And I've included them here so that
            you can have a better feel of who I am and the stacks with which I
            can come up with original applications.
          </p>
        </div>

        {/* filter section start */}
        <div className="app__work-filter app__flex">
          {[
            "UI/UX",
            "Web Design",
            "Web Applications",
            "Desktop Apps",
            "All",
          ].map((item, index) => (
            <div
              key={index}
              onClick={() => handleWorkFilter(item)}
              className={`app__work-filter-item app__flex p-text ${
                activeFilter === item ? "item-active" : ""
              }`}
            >
              {item}
            </div>
          ))}
        </div>

        {/* cards data displaying */}
        {/* <OwlCarousel
          className="owl-theme"
          center={true}
          loop={true}
          nav={true}
          dots={true}
          items="3"
        > */}

        <motion.div
          animate={animateCard}
          transition={{
            duration: 0.5,
            delayChildren: 0.5,
          }}
          className="app__work-portfolio app__flex"
        >
          {filterWork.map((work, index) => (
            // card started

            <motion.div
              transition={{
                stype: "spring",
                bounceStiffness: 3000,
              }}
              whileHover={{ scale: 1.2, originX: 0, cursor: PointerEvent }}
              className="app__work-item app__flex"
              key={index}
            >
              <div className="app__work-img app__flex">
                <img src={urlFor(work.imgUrl)} alt={work.name} />

                {/* PORTFOLIO IMAGES INCONS */}

                <motion.div
                  whileHover={{ opacity: [0, 1] }}
                  translate={{
                    duration: 0.25,
                    ease: "easeInOut",
                    staggerChildren: 0.5,
                  }}
                  className="app__work-hover app__flex"
                >
                  {/* icons started */}
                  {/* <a href={work.projectLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      translate={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <icons.BsEye />
                    </motion.div>
                  </a>

                  <a href={work.projectLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      translate={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <icons.BsGithub />
                    </motion.div>
                  </a> */}
                  {/* icons end! */}
                </motion.div>
              </div>

              <div className="app__work-content app__flex">
                <p className="title-info p-text">
                  <span className="title">{work.title}</span>
                  <span className="tag">{work.tags[0]}</span>
                </p>

                <div className="tech-info p-text">
                  <span className="title">technologies</span>
                  <p className="technologies">{work.technologies}</p>
                </div>
              </div>
              {/* end! */}
            </motion.div>
          ))}
        </motion.div>
        {/* </OwlCarousel> */}
      </div>
    </>
  );
};

export default AppWrap(Work, "Work");
