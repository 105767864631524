import React from "react";
import ProptTypes from "prop-types";
import "./PrograssBar.scss";
import { imgs } from "../../constants";
const PrograssBar = ({ val, max }) => {
  return (
    <>
      {/* <!-- Code for the new pen --> */}

      <div className="container app__mar app__flex">
        <div className="container__progressbars">
          {/* html prograssbar */}
          <div className="progressbar">
            <svg className="progressbar__svg">
              <circle
                cx="80"
                cy="80"
                r="70"
                className="progressbar__svg-circle circle-html shadow-html"
              >
                {" "}
              </circle>
            </svg>
            <span className="progressbar__text shadow-html">
              <img src={imgs.htmlIcon} alt="" />
              HTML
            </span>
          </div>
          {/* end! */}

          {/* html prograssbar */}
          <div className="progressbar">
            <svg className="progressbar__svg">
              <circle
                cx="80"
                cy="80"
                r="70"
                className="progressbar__svg-circle circle-html shadow-html"
              >
                {" "}
              </circle>
            </svg>
            <span className="progressbar__text shadow-html">
              <img src={imgs.cssIcon} alt="" />
              SCSS
            </span>
          </div>
          {/* end! */}

          {/* html prograssbar */}
          <div className="progressbar">
            <svg className="progressbar__svg">
              <circle
                cx="80"
                cy="80"
                r="70"
                className="progressbar__svg-circle circle-html shadow-html"
              >
                {" "}
              </circle>
            </svg>
            <span className="progressbar__text shadow-html">
              <img src={imgs.reactIcon} alt="" />
              React JS
            </span>
          </div>
          {/* end! */}

          {/* html prograssbar */}
          <div className="progressbar">
            <svg className="progressbar__svg">
              <circle
                cx="80"
                cy="80"
                r="70"
                className="progressbar__svg-circle circle-html shadow-html"
              >
                {" "}
              </circle>
            </svg>
            <span className="progressbar__text shadow-html">
              <img src={imgs.nodeIcon} alt="" />
              Node JS
            </span>
          </div>
          {/* end! */}

          {/* html prograssbar */}
          <div className="progressbar">
            <svg className="progressbar__svg">
              <circle
                cx="80"
                cy="80"
                r="70"
                className="progressbar__svg-circle circle-html shadow-html"
              >
                {" "}
              </circle>
            </svg>
            <span className="progressbar__text shadow-html">
              <img src={imgs.javascriptIcon} alt="" />
              JavaScript
            </span>
          </div>
          {/* end! */}
        </div>
      </div>

      {/* <div className="pro">
        <progress value={val} max={max} />
      </div> */}
    </>
  );
};

PrograssBar.ProptTypes = {
  val: ProptTypes.number.isRequired,
  max: ProptTypes.number,
};
PrograssBar.defaultProps = {
  max: 100,
};

export default PrograssBar;
