import React from "react";
import ReactDom from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import components here
import Apps from "./Apps";

//import css file
import "./index.scss";
const root = ReactDom.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Apps />
  </React.StrictMode>
);
