import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";

import { urlFor, client } from "../../client";

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';
    client.fetch(query).then((data) => setAbouts(data));
  }, []);

  return (
    <>
      {/* my services here started */}
      <div className="app__about-container">
        <div className="head-title">
          Get to Know <span>About</span> Me
          <p className="p-text">
            I'm a Full-Stack Developer with more than 5 years of experience that
            is capable of creating both client-side and server-side software. I
            also have modern computer knowledge in areas like HTML, CSS,
            JavaScript, MongoDB, Express, React, Node, JQuery, PHP, C# SQL
            SERVER, My SQL, SQL Plus, SQL Lite, and others.
          </p>
        </div>
        <div className="app__profiles">
          {abouts.map((about, index) => (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: "tween" }}
              className="app__profile-item"
              key={about.title + index}
            >
              <img src={urlFor(about.imgUrl)} alt={about.title} />
              <span>{about.title}</span>
              <p className="p-text">{about.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "About",
  "app__whitebg"
);
