import React, { useState, useEffect } from "react";

import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import reactTooltip from "react-tooltip";

import { urlFor, client } from "../../client";
import { icons, imgs } from "../../constants";

import "./Testimonials.scss";

const Testimonials = () => {
  const handleClick = (index) => {
    setCurrentIndex(index);
  };
  //fetching data..
  const [brands, setBrands] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    // fetching experiences
    const testimonials_query = '*[_type == "testimonials"]';
    const brands_query = '*[_type == "brands"]';

    // fetching testmonials
    client.fetch(testimonials_query).then((data) => {
      setTestimonials(data);
    });
    // fetching brands
    client.fetch(brands_query).then((data) => {
      setBrands(data);
    });
  }, []);

  const testIndex = testimonials[currentIndex];
  return (
    <>
      <div className="app__testimonial-container app__flex">
        {" "}
        {testimonials.length && (
          <>
            <div className="app__testimonial-item ">
              <div className="app__testimonial-btns">
                <div
                  onClick={() =>
                    handleClick(
                      currentIndex === 0
                        ? testimonials.length - 1
                        : currentIndex - 1
                    )
                  }
                >
                  {/* should add the icon */}
                  <icons.BsFillArrowLeftCircleFill />
                </div>
              </div>

              <div className="app__testimonial-content">
                <div className="app__testimonial-info">
                  <img src={urlFor(testIndex.imgUrl)} alt="testimonials" />
                  <p className="p-text">{testIndex.feedback}</p>

                  <h4 className="bold-text">{testIndex.name}</h4>
                  <h5 className="p-text">{testIndex.company}</h5>
                </div>
              </div>

              <div className="app__testimonial-btns">
                <div
                  onClick={() =>
                    handleClick(
                      currentIndex === testimonials.length - 1
                        ? 0
                        : currentIndex + 1
                    )
                  }
                >
                  {/* should add the icon */}
                  <icons.BsFillArrowRightCircleFill />
                </div>
              </div>
            </div>
          </>
        )}
        {/* brands started */}
        <div className="app__testimonials-brands">
          {brands.map((brand) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5, type: "tween" }}
              whileHover={{ scale: 1.1 }}
              key={brand._id}
            >
              <img src={urlFor(brand.imgUrl)} alt={brand.name} />
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonials, "app__testmonials"),
  "Testimonial",
  "app__whitebg"
);
