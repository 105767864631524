import React, { useState } from "react";
//icons
import { icons } from "../../constants";
// style
import "./MobileNavbar.scss";

// active = about;
const MobileNavbar = () => {
  const menuListItems = [
    { icon: <icons.FiHome />, text: "Home" },
    { icon: <icons.FiUser />, text: "About" },
    { icon: <icons.AiOutlineFileDone />, text: "Work" },
    { icon: <icons.GiSkills />, text: "Skills" },
    { icon: <icons.MdRateReview />, text: "Testimonial" },
    { icon: <icons.RiContactsBook2Fill />, text: "Contact" },
  ];

  //select active class by scrolling
  const [active, setActive] = useState([]);

  // const changeActive = () => {
  //   if (window.scrollY >= 100) {
  //     {
  //       {
  //         menuListItems.map((menuListItem, index) => (
  //           <>{setActive(menuListItem.text)}</>
  //         ));
  //       }
  //     }
  //   } else {
  //     setActive("");
  //   }
  // };
  // window.addEventListener("scroll", changeActive);

  return (
    <div className="mobileNavbar-main">
      <div className="app__MobileNavbar">
        <div className="navigation">
          <ul>
            {menuListItems.map((menuListItem, index) => (
              <li
                onClick={() => setActive(`#${menuListItem.text}`)}
                className={
                  active == `#${menuListItem.text}` ? "list active" : "list"
                }
                key={`link-${menuListItem.text}` + index}
              >
                <a href={`#${menuListItem.text}`} key={menuListItem.text}>
                  <span className="icon">{menuListItem.icon}</span>{" "}
                  <span className="text">{menuListItem.text}</span>
                </a>
              </li>
            ))}

            <div className="indicator"></div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
