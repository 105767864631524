import React, { useState, useEffect } from "react";
import "./SkillsPrograssBar.scss";

import { urlFor, client } from "../../client";
import { icons, imgs } from "../../constants";
const SkillsPrograssBar = () => {
  // fetching Data from sanity

  //   state variables for fething
  const [programmingSkills, setprogrammingSkills] = useState([]);
  const [databaseSkills, setdatabaseSkills] = useState([]);
  const [frameworkSkills, setframeworkSkills] = useState([]);
  const [toolSkills, settoolSkills] = useState([]);
  //   fetching data to state variables
  useEffect(() => {
    // fetching Programming Skills started
    const programmingSkillsQuery = '*[_type == "programmingSkills"]';
    client.fetch(programmingSkillsQuery).then((data) => {
      setprogrammingSkills(data);
    });

    // fetching Database skills started
    const databaseSkillsQuery = '*[_type == "databaseSkills"]';
    client.fetch(databaseSkillsQuery).then((data) => {
      setdatabaseSkills(data);
    });

    // fetching FrameWroks skills started
    const frameworkSkillsQuery = '*[_type == "frameworkSkills"]';
    client.fetch(frameworkSkillsQuery).then((data) => {
      setframeworkSkills(data);
    });

    // fetching Tools skills started
    const toolSkillsQuery = '*[_type == "toolSkills"]';
    client.fetch(toolSkillsQuery).then((data) => {
      settoolSkills(data);
    });
  }, []);

  return (
    <>
      {/* Programming Section started */}
      <div className="app__pro-container">
        <div className="title p-text">
          <span>Programming</span>
        </div>
        {/* skills section started */}

        {programmingSkills.map((programmingSkill) => (
          <div key={programmingSkill.title} className="app__pro-item p-text">
            <div className="app__pro-content">
              <img src={urlFor(programmingSkill.imgUrl)} alt="programIcon" />
              <span>{programmingSkill.title}</span>
            </div>

            <div className="app__pro-bar">
              <ProgressBar
                color="#CE9F17"
                color2="#CE9F17"
                value={programmingSkill.value}
                maxValue="100"
                rotation="202"
              />
            </div>
          </div>
        ))}
        {/* end! */}
      </div>
      {/* section end! */}

      {/* Database Section started */}
      <div className="app__pro-container">
        <div className="title p-text">
          <span>Database</span>
        </div>
        {/* skills section started */}

        {databaseSkills.map((databaseSkill) => (
          <div key={databaseSkill.title} className="app__pro-item p-text">
            <div className="app__pro-content">
              <img src={urlFor(databaseSkill.imgUrl)} alt="programIcon" />
              <span>{databaseSkill.title}</span>
            </div>

            <div className="app__pro-bar">
              <ProgressBar
                color="#CE9F17"
                color2="#CE9F17"
                value={databaseSkill.value}
                maxValue="100"
                rotation="202"
              />
            </div>
          </div>
        ))}
        {/* end! */}
      </div>
      {/* section end! */}

      {/* Frameworks Section started */}
      <div className="app__pro-container p-text">
        <div className="title">
          <span>Frameworks</span>
        </div>
        {/* skills section started */}

        {frameworkSkills.map((frameworkSkill) => (
          <div key={frameworkSkill.title} className="app__pro-item">
            <div className="app__pro-content">
              <img src={urlFor(frameworkSkill.imgUrl)} alt="programIcon" />
              <span>{frameworkSkill.title}</span>
            </div>

            <div className="app__pro-bar">
              <ProgressBar
                color="#CE9F17"
                color2="#CE9F17"
                value={frameworkSkill.value}
                maxValue="100"
                rotation="202"
              />
            </div>
          </div>
        ))}
        {/* end! */}
      </div>
      {/* section end! */}

      {/* Tools Section started */}
      <div className="app__pro-container p-text">
        <div className="title">
          <span>Tools</span>
        </div>
        {/* skills section started */}

        {toolSkills.map((toolSkill) => (
          <div key={toolSkill.title} className="app__pro-item">
            <div className="app__pro-content">
              <img src={urlFor(toolSkill.imgUrl)} alt="programIcon" />
              <span>{toolSkill.title}</span>
            </div>

            <div className="app__pro-bar">
              <ProgressBar
                color="#CE9F17"
                color2="#CE9F17"
                value={toolSkill.value}
                maxValue="100"
                rotation="202"
              />
            </div>
          </div>
        ))}
        {/* end! */}
      </div>
      {/* section end! */}
    </>
  );
};

export default SkillsPrograssBar;

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 0,
    };

    // this.targetPercentage = this.title.value;

    this.targetPercentage = this.props.value / this.props.maxValue;
  }

  progressBarStyle() {
    return {
      backgroundImage:
        "linear-gradient(" +
        this.props.rotation +
        "deg, " +
        this.props.color +
        ", " +
        this.props.color2 +
        ")",
      maxWidth: this.state.maxWidth,
      backgroundColor: this.props.color,
      borderRadius: this.getRadius(),
    };
  }

  componentDidMount() {
    setTimeout(
      () => this.setState({ maxWidth: this.targetPercentage * 100 + "%" }),

      10
    );
  }

  getRadius() {
    return (
      "5px " + (this.targetPercentage * 100 >= 100 ? "5px 5px" : "0 0") + " 5px"
    );
  }

  render() {
    return (
      <div className="progress-bar-wrapper">
        <div className="progress-bar">
          <div
            className="progress-bar-progress"
            style={this.progressBarStyle()}
          >
            <div className="percentage">
              {Math.round(this.targetPercentage * 100)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
