// here import all images from assets folder that you can use that anywhere
import mypic from "../assets/mypic.png";
import ab1 from "../assets/about/ab1.jpg";
import emailIcon from "../assets/emailIcon.png";
import mobileIcon from "../assets/mobileIcon.png";

//header imgs
import headerProfile from "../assets/headerProfile.png";
import headerImageCircle from "../assets/headerImageCircle.png";
import reactIcon from "../assets/reactIcon.png";
import phpIcon from "../assets/phpIcon.png";
import sassIcon from "../assets/sassIcon.png";
import nodeIcon from "../assets/nodeIcon.png";
import mysqlIcon from "../assets/mysqlIcon.png";
import cIcon from "../assets/cIcon.png";
import cssIcon from "../assets/cssIcon.png";
import databaseIcon from "../assets/databaseIcon.png";
import githubIcon from "../assets/githubIcon.png";
import htmlIcon from "../assets/htmlIcon.png";
import jqueryICon from "../assets/jqueryICon.png";
import vsIcon from "../assets/vsIcon.png";
import javascriptIcon from "../assets/javascriptIcon.png";

// export all imported images and use them any ever
export default {
  //header imgs
  cssIcon,
  databaseIcon,
  githubIcon,
  htmlIcon,
  jqueryICon,
  vsIcon,
  headerProfile,
  headerImageCircle,
  reactIcon,
  phpIcon,
  sassIcon,
  nodeIcon,
  cIcon,
  mysqlIcon,
  //navbar Imgs
  mypic,
  javascriptIcon,
  ab1,
  emailIcon,
  mobileIcon,
};
